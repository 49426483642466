import React from "react";
import { graphql } from "gatsby"
import { Navigation } from "../components/global/nav"
import { Footer } from "../components/global/footer";
import authors from "/src/resources/author-directory";
import { FaCompass } from "react-icons/fa"
import "../styles.css"
import Breadcrumbs from "../components/global/breadcrumbs";
import SEO from "../components/global/seo";

const BlogTemplate = (props) => {
   const page = props.data.contentfulBlog;
   const content = page.content.internal.content;
   const title = page.title;
   const title_split = title.split("-")
   const slug = page.slug;
   const metadescription = page.metaDescription;
   const image = page.image.file.url;
   
   const date_update= (new Date(page.updatedAt)).toLocaleDateString("en-GB");
   
   const content_word_count = content.split(" ").length + 1;
   const reading_time = (content_word_count/250).toFixed(0)

   const printInternalNavigation = () => {
      const string = page.navigation.internal.content;
      const json = JSON.parse(string)
      const array = Object.entries(json)

      let jsx = [];

      for (let i = 0; i < array.length; i++) {
         const el = array[i];
         const keyVAl = Object.entries(el[1])
         
         jsx.push(
            <a key={i} href={`#${keyVAl[0][1]}`}><li>{keyVAl[0][0]}</li></a>
         )
      }

      return jsx;
   }

   const articleDescription = () => {
      const description = page.articleDescription.internal.content;
      return {__html: description}
   }

   const bodyContent = () => {
      return {__html: content}
   }

   return (
      <React.Fragment>
         <SEO
            title={title}
            slug={slug}
            metadescription={metadescription}
            image={`https:${image}`}
            titleTemplate="%s"
         />
         <Navigation/>
         <div className="manufacturer-title-container">
            <div className="manufacturer-title-inner-container body-inner-width">
               <div className="manufacturer-title-text">
                  <h1>{title_split[0]}<br/>
                  <span id="title_part2">{title_split[1]}</span></h1>

                  {/*
                     page.company ? (
                        <div className="at-a-glance-container">
                           <p>Company type: <span>{page.companyType}</span></p>
                           <p>HQ: <span>{page.hq}</span></p>
                           <p>Founded: <span>{page.yearFounded}</span></p>
                           <p>Closed: <span>{page.yearClosed}</span></p>
                        </div>
                     ) : null
                  */}

                  <span dangerouslySetInnerHTML={articleDescription()}></span>
               </div>

               <div className="manufacturer-title-image">
                  <img
                     src={`https:${image}`}
                  />
               </div>
            </div>
         </div>

         <div className="standard-container">
            <div className="standard-inner-container body-inner-width">
               <div className="standard-content-container">
                  
                  {/* CONTENT */}
                  <div className="content-column">
                     <Breadcrumbs
                        location={slug}
                     />

                     {/* ARTICLE DETAILS */}
                     <div className="article-details-container">
                        <p className="article-detail article-last-updated">Last updated - <span>{date_update}</span></p>
                        <p className="article-detail article-reading-time">Estimated reading time - <span>{reading_time} minutes</span></p>
                     </div>

                     {/* BODY */}
                     <div className="content-body">
                        <span dangerouslySetInnerHTML={bodyContent()}/>
                     </div>
                  </div>

                  {/* SIDEBAR */}
                  <div className="navigation-column">
                     <div className="navigation-entries-container">
                        <div className="article-navigation-ol-container">
                           <div className="article-navigation-ol-header">
                              <FaCompass/>&nbsp;&nbsp;Navigation
                           </div>

                           <ol className="article-navigation-ol">
                              {printInternalNavigation()}
                           </ol>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Footer/>
      </React.Fragment>
   )
}

export default BlogTemplate

export const pageQuery = graphql`
   query ContentfulBlogPostsBySlug( $slug: String! ) {
      contentfulBlog ( slug: { eq: $slug }) {
         title
         metaDescription
         updatedAt
         content {
            internal {
               content
            }
         }
         navigation {
            internal {
               content
            }
         }
         articleDescription {
            internal {
               content
            }
         }
         image {
            file {
               url
            }
         }
         slug
         company
      }
   }
`